#singleBook .carousel.carousel-slider{
	box-shadow: 2px 2px 5px #000;
}
#singleBook ul.thumbs.animated{
	padding: 0;
}
#singleBook .carousel.carousel-slider{
	margin-top: 0;
	padding: 0;
	border-radius: 0;
}
#singleBook .carousel{
	background-color: #304d73;
	padding: 15px;
}
.border-radius-15{
	border-radius: 15px;
}
#singleBook ul, .carousel .thumbs-wrapper{
	margin: 0 !important;
}
.carousel .thumb{
	border: none;
}
#singleBook .carousel-indicators {
	width: auto;
	bottom: -45px !important;
	left: 0 !important;
}