.input-darker{
    background-color: rgba(0,0,0,0.5) !important;
    color: white !important;
}
.input-darker::placeholder{
    color:white !important;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 5px;
  }
   
  /* Handle */
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.3); 
    border-radius: 5px;
  }
  
  /* Handle on hover */
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(0,0,0,0.5);
  }